<script lang="ts">
  interface Props {
    class?: string;
  }

  let { class: clazz = 'w-6 h-6' }: Props = $props();
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  class={clazz}
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
  stroke-width="2"
>
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
